<template>
    <section class="booking-management">
        <div class="form-search-container bg-white text-center">
            <el-form :model="formSearch" inline size="small">
                <el-form-item>
                    <el-input v-model="formSearch.name" placeholder="请输入承租人名称搜索" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="formSearch.phone" placeholder="请输入承租人电话搜索" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="handleSearch">查询</el-button>
                    <el-button @click="handleReset">重置</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="table-container bg-white">
            <r-e-table ref="communityTableRef" :dataRequest="getCommunityTableData" :columns="tableColumn" :height="795"
                       :query="formSearch">
                <template slot="empty">
                    <el-empty/>
                </template>
                <el-table-column slot="toolbar" label="操作" width="100">
                    <template slot-scope="{ row }">
                        <div class="table-tools">
                            <span class="table-btn" v-if="row.status === 0" @click="onHandle(row,1)">确认</span>
                            <span class="table-btn" v-if="row.status === 1" @click="onHandle(row,2)">已看</span>
                        </div>
                    </template>
                </el-table-column>
            </r-e-table>
        </div>
    </section>
</template>

<script>
    import {tableColumn} from "@/views/rental-management/booking-management/data";
    import {getBookingListApi, getBookingPutStatus} from "@/api/booking-management";
    import {MessageSuccess, MessageError, MessageInfo, MessageWarning} from "@custom/message";
    export default {
        name: "booking-management",
        data() {
            return {
                tableColumn: tableColumn(this),
                followStatus: [],
                formSearch: {
                    name: "",
                    phone: "",
                }
            };
        },
        async created() {
            // 预约跟进状态 600400
            this.followStatus = await this.$store.dispatch("app/getDictionaryByCodeActions", "600400");
        },
        methods: {
            handleSearch() {
                // 搜索
                this.$refs.communityTableRef.getTableData();
            },
            handleReset() {
                // 重置
                this.formSearch.name = "";
                this.formSearch.phone = "";
                this.handleSearch();
            },
            getCommunityTableData(params) {
                // 获取预定管理列表
                return getBookingListApi(params);
            },
            onHandle(row, status) {
                let that = this;
                const {uuid} = row;
                const params = {uuid, status};
                const msg = status === 1 ? '此操作将会确认用户预约看房, 是否继续' : '此操作将会把用户的预约状态改为已看房, 是否继续?';
                this.$confirm(`${msg}`, '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    const loading = this.$loading({lock: true, text: "请您稍等...", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)"});
                    getBookingPutStatus(params).then((res) => {
                        loading.close();
                        that.handleReset();
                        MessageSuccess('操作成功!');
                    }).catch((err) => loading.close());
                }).catch(() => {
                    this.$message({type: 'info', message: '已取消操作'});
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .booking-management {
        padding: 15px;

        .form-search-container {
            padding-top: VH(20px);
            margin-bottom: VH(15px);

            /deep/ .el-form {
                .el-form-item .el-input__inner {
                    width: VW(250px);
                }
            }
        }
    }
</style>
