<template>
    <section class="payment-record">
        <div class="top-bar bg-white">
            <el-input v-model="formSearch.keyword" placeholder="请输入关键字搜索" style="margin: 0 9px 0 7px; width: 250px"
                      size="small"/>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
            <el-button type="primary" size="small" @click="handleExport">导出数据</el-button>
            <el-button type="info" size="small" @click="handleBlacklist">黑名单</el-button>
            <!--               <el-button type="primary" size="small" @click="openmessage({})">消息推送管理</el-button>-->
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getLeasorTableData" :columns="tableColumn"
                   :query="formSearch" :height="790" :notAuto="true" :afterDataReq="afterDataReq">

            <template slot="empty">
                <el-empty/>
            </template>
<!--陈守亮-2024-1-19-发票暂时隐藏-->
          <el-table-column slot="toolbar" label="发票抬头" width="100">
            <template #default="{ row }">
              <el-button  @click="fapiaoclick(row)" type="text" >抬头</el-button>
            </template>
          </el-table-column>
        </r-e-table>
        <drawer-tenant-info ref="drawerTenantInfo" @handleSearch="handleSearch"/>
        <drawer-message-info modal-append-to-body="false" ref="drawerMessageInfo" @handleSearch="handleSearch"/>
<!--      2023-12-28-陈守亮-抬头组件-->
      <dialogAddInvoice ref="dialogAddInvoiceref"  @handleSearch="handleSearch"></dialogAddInvoice>
    </section>
</template>

<script>
import {tableColumn} from "@/views/rental-management/tenant-management/data";
import {getLeasorList, leasorExport} from "@/api/tenant-management";
import {downloadByData} from "@/utils/hooks/download";
import {timeFormat} from "@custom/index";
import RETable from "@/components/Gloabl/r-e-table/index.vue";

export default {
    name: "batch-meter-reading",
    data() {
        return {
            tableColumn: tableColumn(this),
            tableData: [],
            formSearch: {
                keyword: ""
            },
        };
    },
    components: {
      RETable,
        drawerTenantInfo: () => import("@/views/rental-management/tenant-management/components/drawer-tenant-info.vue"),
        drawerMessageInfo: () => import("@/views/rental-management/tenant-management/components/drawer-message-info.vue"),
        // 2023-12-28-陈守亮-抬头组件
        dialogAddInvoice: () => import('@/views/rental-management/tenant-management/components/dialog-add-invoice.vue')
    },

    mounted() {
        let {params: {data}} = this.$route;
        if (data) {
            const {leasorName} = data;
            if (leasorName) this.formSearch.keyword = leasorName;
        }
        this.handleSearch();
    },
    methods: {
      getLeasorTableData(params) {
            return getLeasorList(params);
        },
        handleSearch() {
            this.$refs["orderTableRef"].getTableData(false);
        },
        handleReset() {
            this.formSearch = {keyword: ""};
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        openDrawer(row) {
            this.$refs["drawerTenantInfo"].openDrawer(row);
        },
        openmessage(row) {
            // 这个调用了
            this.$refs["drawerMessageInfo"].openmessage(row);
        },

        handleExport() {
            const {keyword} = this.formSearch;
            const loading = this.$loading({
                lock: true,
                text: "正在导出请您稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });
            const paramsData = {keyword}
            leasorExport(paramsData).then(res => {
                downloadByData({data: res, filename: `租户管理${timeFormat(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.xls`});
                loading.close();
            }).catch(err => loading.close());
        },
        messagePush() {
            // const {keyword} = this.formSearch;
            const loading = this.$loading({
                lock: true,
                text: "正在跳转请您稍等...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            });

            // const paramsData = {keyword}
            // leasorExport(paramsData).then(res=>{
            //      downloadByData({data: res, filename: `租户管理${timeFormat(new Date(),'yyyy-MM-dd-HH-mm-ss')}.xls`});
            //      loading.close();
            // }).catch(err => loading.close());

        },
        afterDataReq(row) {
            if (row.length === 1) {
                this.openDrawer(row[0]);
            }
            return row;
        },
      // 2023-12-28-陈守亮-发表弹框事件
      fapiaoclick(row){

        this.$refs.dialogAddInvoiceref.openinvoiceclick(row)
      },
    //2024/08/22 张晓瑜新增黑名单
    handleBlacklist(){
        this.$router.push({ name: 'blacklist-tenants', query: {  } })
    }
    }
};
</script>

<style lang="scss" scoped>
.payment-record {
    padding: VH(15px) VW(15px);

    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            // white-space: nowrap;
            text-align: center;
        }
    }
}
</style>
