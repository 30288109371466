<template>
  <section
      @click.stop="pickerclick"
      class="house-resource flex justify-between"
      style="position: relative"
  >
    <!-- 头部条件查询 -->
    <div class="form-search">
      <el-form :model="formSearch" inline size="small">
        <el-form-item>
          <el-select ref="paymentDisabledRef" v-model="formSearch.paymentDisabled"
                     style="width: 140px"
                     placeholder="请选择房间状态">
            <el-option label="全部"
                       value=""/>
            <el-option label="禁用"
                       :value="true"/>
            <el-option label="正常"
                       :value="false"/>
          </el-select>
        </el-form-item>
        <!--                                        <el-form-item>-->
        <!--                                            <el-select v-model="formSearch.leaseModeCode" clearable placeholder="请选择租赁方式">-->
        <!--                                                <el-option v-for="item in leaseStatus" :key="item.code" :label="item.codeName"-->
        <!--                                                           :value="item.code"/>-->
        <!--                                            </el-select>-->
        <!--                                        </el-form-item>-->
        <!--                                        <el-form-item>-->
        <!--                                            <el-select v-model="formSearch.contractStatusCode" clearable placeholder="请选择合同状态">-->
        <!--                                                <el-option v-for="item in contractStatus" :key="item.code" :label="item.codeName"-->
        <!--                                                           :value="item.code"/>-->
        <!--                                            </el-select>-->
        <!--                                        </el-form-item>-->
        <el-form-item>
          <el-input
              v-model="formSearch.keyword"
              placeholder="请输入关键词搜索"
              clearable
          />
        </el-form-item>
        <el-form-item>
          <el-select
              ref="nameRef"
              v-model="formSearch.name"
              clearable
              filterable
              allow-create
              default-first-option
              placeholder="请选择房源筛选"
              style="width: 150px; margin-right: 5px"
          >
            <el-option label="全部"
                       value=""/>
            <el-option
                v-for="item in houseTypes"
                :key="item.code"
                :label="item.codeName"
                :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
              ref="districtRef"
              v-model="formSearch.district"
              clearable
              filterable
              allow-create
              default-first-option
              placeholder="请选择大区筛选"
              style="width: 130px; margin-right: 5px"
          >
            <el-option label="全部"
                       value=""/>
            <el-option
                v-for="(item, index) in options"
                :key="index"
                :label="item"
                :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
          <el-button type="primary" @click="handleExport">导出数据</el-button>
          <el-button v-if="isChange === true" type="primary" @click="jiejinclick(true)">批量解禁</el-button>
          <el-button v-if="isChange === true" type="primary" @click="jiejinclick(false)">批量禁用</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 左侧树列表 -->
    <div class="community-tree-container bg-white">
      <r-e-tree
          title="富航美寓"
          ref="communityRef"
          :data="communityTreeData"
          :node-label="createNodeLabel"
          :default-expanded-keys="defaultTreeNodeKeys"
          expand-on-click-node
          @node-click="handleClickCommunity"
          v-loading="loading"
          element-loading-background="rgba(0, 0, 0, 0)"
      />
    </div>
    <!-- 2023.07.26 张晓瑜新增切换按钮 -->
    <div
        style="
            position: absolute;
            top: 17px;
            right: 140px;
            border-radius: 4px;
            box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);
            z-index: 999;"
    >
      <el-button @click="isChange = !isChange">切换</el-button>
    </div>
    <!-- 中间内容区域 -->
    <div class="house-resource-container">
      <!-- 头部房间剩余统计 -->
      <div class="card-search-content flex align-center">
        <div class="house-lease-status house-status flex bg-white">
          <div
              class="item flex align-center justify-center"
              v-for="item in houseStatus"
              :key="item.code"
              :class="{ 'is-active-tab': activeHouseTab === item.code }"
              @click="handleHouseStatus(item, 'house')"
          >
            <div class="label">{{ item.codeName }}</div>
            <div class="value">{{ item.value || 0 }}</div>
          </div>
          <div
              class="item flex align-center justify-center"
              @click="getActivity"
              :class="{ 'is-active-tab': activity }"
          >
            <div class="label">活动中</div>
          </div>
        </div>
        <div
            class="house-lease-status lease-status flex bg-white"
            style="margin-left: -18px"
        >
          <div
              class="item flex align-center justify-center"
              v-for="item in leaseStatus"
              :class="{ 'is-active-tab': activeLeaseTab === item.code }"
              :key="item.code"
              @click="handleLeaseStatus(item, 'lease')"
          >
            <div class="label">{{ item.codeName }}</div>
            <div class="value">{{ item.value || 0 }}</div>
          </div>
        </div>
      </div>
      <!-- 图表展示信息 -->
      <div v-if="isChange === false">
        <div class="house-node-content flex align-center">
          <div class="node-name">{{ buildNickname || "--" }}</div>
          <div class="floor-number">【 {{ floorNumber.label }}</div>
          <div class="house-total">{{ page.totalNum }} 间 】</div>
          <el-button
              type="primary"
              icon="el-icon-plus"
              size="mini"
              v-if="addApartment"
              @click="handleAddHouse"
          >
            添加房源
          </el-button>
        </div>
        <template v-if="currentNodeHouseList.length > 0"
        >
          <el-row class="house-content hidden-scrollbar"

          >
            <!-- 富乐新城中间卡片组件 -->
            <card-house
                @handleSearch="handleSearch"
                :house-status="houseStatus"
                :data="currentNodeHouseList"
                @on-click="handleClickHouse"
            />
          </el-row>
          <el-pagination
              class="text-right"
              :current-page="page.page"
              :page-sizes="[10, 50, 100, 1000, 10000]"
              :page-size="page.pageSize"
              :pager-count="7"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.totalNum"
              @size-change="sizeChange"
              @current-change="pageChange"
          />
        </template>
        <el-empty style="margin-top: 100px" v-else :image-size="200"/>
      </div>
      <!-- 2023.07.26张晓瑜新增列表展示 -->
      <div
          style="padding: 20px; width: 100%; margin-top: -40px; height: 700px"
          v-if="isChange === true"
      >
        <div style="width: 90%; max-height: 800px">
          <r-e-table
              :notAuto="true"
              ref="HouseListTableRef"
              :data="currentNodeHouseList"
              :columns="tableColumn"
              :height="800"
              :showPagination="false"
              el-table-column
              @selection-change="myhandleSelectionChange"
          >
            <template slot="empty">
              <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="120">
              <template slot-scope="{ row }">
                <div class="table-tools">
                                    <span class="table-btn" @click="handleClickHouse(row)">
                                        详情
                                    </span>
                  <span
                      class="table-btn"
                      v-if="addApartment"
                      @click="handleAddHouse"
                  >添加房源</span>
                </div>
              </template>
            </el-table-column>
          </r-e-table>
          <el-pagination
              class="text-right"
              :current-page="page.page"
              :page-sizes="[10, 50, 100, 1000, 10000]"
              :page-size="page.pageSize"
              :pager-count="7"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.totalNum"
              @size-change="sizeChange"
              @current-change="pageChange"
          />
        </div>
      </div>
    </div>
    <!-- 右侧楼层 -->
    <div
        class="floor-container"
        style="position: absolute; right: 0; margin-top: 22px"
        v-if="isChange === false"
    >
      <div class="floor-name text-center bg-white" @click="handleFloor(0)">
        楼层
      </div>
      <div class="floor-content bg-white hidden-scrollbar">
        <div
            v-for="item in layerMax"
            :key="item"
            class="floor text-center"
            :class="{ 'is-active-floor': item === activeFloor }"
            @click="handleFloor(item)"
        >
          {{ layerMax + 1 - item }}F
        </div>
      </div>
    </div>

    <!--    新增房源弹窗      -->
    <layer-add-house title="新增房源" ref="layerHouseRef"/>

    <!--    房源详情抽屉弹窗     -->
    <drawer-house-info :house-status="houseStatus" ref="drawerHouseRef" @handleSearch="handleSearch"/>
  </section>
</template>

<script>
import {tableColumn} from "./data";
import {cardHouse, drawerHouseInfo, layerAddHouse} from "./components";
import {getCountListAPI, getHouseAllListApi, apartmentExportApi} from "@/api/house-resource";
import {getAddressList} from "@/api/community-management";
import {downloadByData} from "@/utils/hooks/download";
import {timeFormat} from "@custom/index";
import {
  getHouseListByCommunity,
  getHouseListByCommunityfalse,
  getHouseListByCommunitysiok
} from "../../../api/house-resource";
import {MessageSuccess} from "@custom/message";

export default {
  name: "house-resource",
  data() {
    return {
      rowlist: [],
      jinzhiStatus: [],//头部搜索是否被禁止房间选项
      loadingcread: false,
      buildNickname: "",
      tableColumn: tableColumn(this),
      formSearch: {
        keyword: null, // 小区/房间名搜索关键字
        paymentDisabled: null,
        // filterStatusCode: [],
        // contractStatusCode: "",
        // leaseModeCode: "",
        name: null,
        district: null,
      }, // 头部条件查询
      communityTreeData: [], // 左侧树列表数据
      defaultTreeNodeKeys: [], //默认展开的节点的 key 的数组
      houseStatus: [],
      contractStatus: [],
      leaseStatus: [],
      activeHouseTab: "",
      activeLeaseTab: "",
      activeFloor: 0,
      layerMax: 34,

      searchType: "node",
      currentNodeHouseList: [],
      // 默认条数展示
      page: {
        pageSize: 50,
        page: 1,
        totalNum: 0,
      },

      currentNode: {},
      apartmentsCount: 0,
      state: 0,
      whole: 0,
      houseTypes: [],
      options: [],
      activity: null,
      isChange: false, // 是否切换
      buildUuid: 0, // 小区、楼、单元 uuid
      buildType: 0, // uuid类型 1为小区 2为楼幢，3为单元
      uuid: 0,
      type: 0,
      uuidType: 0,
      addApartment: false, //是否添加房源
      numberSum: {},
      loadingOptions: {
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      },
      loading: true
    };
  },
  components: {cardHouse, drawerHouseInfo, layerAddHouse},
  computed: {
    canAddHouse() {
      // 判断当前节点是否可以添加房源；1，已选节点；2，当前节点是楼幢且没有单元或当前节点是单元；
      const currentNode = this.currentNode;
      return (
          JSON.stringify(currentNode) !== "{}" &&
          [2, 3].includes(currentNode.type) &&
          currentNode.layerNum === 1
      );
    },
    floorNumber() {
      const number = this.activeFloor
          ? this.layerMax + 1 - this.activeFloor
          : 0;
      return {label: number ? `${number}F` : "全部楼层", value: number};
    },
  },
  async created() {
    let that = this;
    // 房间状态筛选 600310(暂时不使用)，房源管理 600000
    this.houseStatus = await this.$store.dispatch(
        "app/getDictionaryByCodeActions",
        600000
    );
    // console.log(this.houseStatus)
    // 合同状态 600320
    this.contractStatus = await this.$store.dispatch(
        "app/getDictionaryByCodeActions",
        600320
    );
    // 租赁方式 600330
    this.leaseStatus = await this.$store.dispatch(
        "app/getDictionaryByCodeActions",
        600330
    );
    // 获取所有小区楼幢单元
    await this.getCommunityTreeData();
    // 获取房源类型
    this.houseTypes = await this.$store.dispatch(
        "app/getDictionaryByCodeActions",
        600210
    );

    let list = await this.$store.dispatch(
        "app/getDictionaryByCodeActions",
        600220
    );
    //拼接房源类型
    this.houseTypes = this.houseTypes.concat(list);

    let res = await getAddressList();
    this.options = res.list;
    // this.options = this.options.map(item => {
    //   if(item === '五项新区') {
    //     item = '良庆区'
    //   }
    //   return item
    // })
    console.log(this.options,'五项四号点')

    // await getHouseListByCommunity({paymentDisabled:false}).then(res => {
    //   // console.log(res,'我是禁止搜索选项')
    // })
    // const {
    //   query: { data }
    // } = this.$route
    // if (data) {
    //   const { apartmerntName } = data
    //   if (apartmerntName) {
    //     that.formSearch.keyword = apartmerntName
    //     await that.handleSearch()
    //     const apartmernt = that.currentNodeHouseList[0]
    //     await that.handleClickHouse(apartmernt)
    //   }
    // }
    const {
      params: {data = null},
    } = this.$route;

    if (data) {
      const {code = null} = data;
      if (code) {
        that.formSearch.name = code;
        await that.handleSearch();
      }
    }
    this.loading = false
    this.loadingcread = false
  },
  async mounted() {
    let that = this;
    // 楼层选择器滚动到最底部
    const floorContent = document.querySelector(".floor-content");
    floorContent.scrollTop = floorContent.scrollHeight;
    // 2023/08/16 张晓瑜修改页面具体房间跳转接参
    const {
      query: {data},
      params: {datas},
    } = this.$route;
    if (data) {
      const {apartmerntName} = data;
      if (apartmerntName) {
        that.formSearch.keyword = apartmerntName;
        await that.handleSearch();
        const apartmernt = that.currentNodeHouseList[0];
        await that.handleClickHouse(apartmernt);
      }
    }
    // 2023/08/17 张晓瑜新增首页小区动态(子级)跳转接参=
    if (datas) {
      const {name} = datas;
      if (name) {
        that.formSearch.keyword = name;
        await that.handleSearch();
      }
    }
  },
  methods: {
    // 2024-4-17-新增点击空白关闭组件
    pickerclick(e) {
      this.$refs['nameRef'].blur();
      this.$refs['paymentDisabledRef'].blur();
      this.$refs['districtRef'].blur();
    },
    // 获取所有小区楼幢单元树tree数据
    async getCommunityTreeData(currentNode) {
      // vuex存放数状列表
      const {list = []} = await this.$store.dispatch(
          "community/GetCommunityListActions",
          this.formSearch
      );
      this.communityTreeData = list;
      if (list.length > 0) {
        // 如果当前节点存在，则再次选择当前节点，用于新增房源后，刷新tree数据
        // 默认选择第一个节点
        const node =
            currentNode && JSON.stringify(currentNode) !== "{}"
                ? currentNode
                : list[0];
        this.$nextTick(() => {
          // 默认打开的节点，并获取当前节点数据
          this.defaultTreeNodeKeys = [node.uuid]; //默认展开key的数组
          // 通过ref属性调用子组件内部的方法控制选中状态
          this.$refs.communityRef.setDefaultCurrentNodeKey(node.uuid);
          this.handleClickCommunity(node);
        });
      }
    },
    // 生成tree节点，房源数量
    createNodeLabel(h, node) {
      return h(
          "span",
          {
            class: "bound-count",
            style: {
              color: "#2688F6",
              fontWeight: "bold",
            },
          },
          node.data.apartmentsCount
      );
    },
    // 点击树节点被点击时的回调
    handleClickCommunity(data) {
      // alert("我被点击了");
      const currentNode = data;
      this.numberSum = data;
      this.addApartment =
          JSON.stringify(currentNode) !== "{}" &&
          [2, 3].includes(currentNode.type) &&
          currentNode.layerNum === 1;
      const {type, uuid, name, layerMax} = data;
      // 获取当前节点的type和uuid
      this.buildType = type;
      this.buildUuid = uuid;
      this.uuid = uuid;
      this.uuidType = type;
      // 点击小区树节点，获取数据
      // this.currentNode = data
      this.buildNickname = name;
      // 获取当前节点（小区/单元/楼幢）的房源列表;
      this.searchType = "node";
      this.layerMax = layerMax || 34;
      this.activeFloor = 0;
      // 获取房源列表
      // this.getTreeData()
      this.getCurrentNodeHouseList();
      this.getHouseAllListCount();
      // this.getHouseAllListCount()
      // this.getCountList()
    },
    // 获取所有小区楼幢单元树tree数据
    async getTreeData() {
      const {list = []} = await this.$store.dispatch(
          "community/GetCommunityListActions",
          this.formSearch
      );
      this.communityTreeData = list;
      if (list.length > 0) {
        let data = this.communityTreeData[0];
        const {type, uuid} = data;
        this.buildType = null;
        this.buildUuid = null;
        this.uuid = uuid;
        this.uuidType = type;
      }
      // await this.getCurrentNodeHouseList()
      // await this.getHouseAllListCount()
    },
    // 全局搜索房源
    async handleSearch() {

      this.searchType = "global";
      this.currentNode = {name: "全部小区"};
      this.buildNickname = "全部小区";
      this.defaultTreeNodeKeys = [];
      await this.getTreeData();
      await this.getCurrentNodeHouseList();
      await this.getHouseAllListCount();
      await getHouseListByCommunity({paymentDisabled: this.formSearch.paymentDisabled}).then(res => {
        console.log(res.list, '我是禁止搜索选项')
        this.communityTreeData = res.list
      })
      //  this.$refs['HouseListTableRef'].getTableData()
    },
    // 重置全局搜索
    async handleReset() {
      this.formSearch = {
        keyword: null,
        filterStatusCode: [],
        contractStatusCode: null,
        leaseModeCode: null,
      };
      this.activity = null;
      this.searchType = "global";
      this.currentNode = {name: "全部小区"};
      // 获取树列表
      await this.getCommunityTreeData();
      // 获取房源列表
      await this.getCurrentNodeHouseList();
      // await this.getHouseAllListCount()
    },
    // 点击头部房间taber统计筛选
    handleHouseStatus(item, houseLease = "house") {
      // this.loadingcread = true
      this.loadingq = this.$loading({
        lock: true,
        text: '房源加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      // console.log(item);
      this.activity = false;
      const name = houseLease === "house" ? "activeHouseTab" : "activeLeaseTab";
      this[name] = this[name] !== item.code ? item.code : "";
      this.state = item.code;
      // 获取房源列表
      this.getCurrentNodeHouseList();
    },
    // 顶部整租合租
    handleLeaseStatus(item, houseLease = "house") {
      this.loadingq = this.$loading({
        lock: true,
        text: '房源加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const name = houseLease === "house" ? "activeHouseTab" : "activeLeaseTab";
      this[name] = this[name] !== item.code ? item.code : "";
      this.whole = item.code;
      this.getCurrentNodeHouseList();
      //  this.$refs['HouseListTableRef'].getTableData()
    },
    // 获取房源列表数据
    async getCurrentNodeHouseList() {
      // searchType搜索类型
      // global获取全部的房源列表，并全局搜索使用form的keyword;
      // node点击树节点获取当前节点（小区/单元/楼幢）的房源列表;
      const floorNumber = this.floorNumber.value;
      // const keyword = this.currentNode.name
      const formData = {
        ...this.formSearch,
        floorNumber,
        state: this.state,
        whole: this.whole,
        activity: this.activity,
        buildUuid: this.buildUuid,
        buildType: this.buildType,
      };
      // formData.keyword = this.searchType === 'node' ? keyword : formData.keyword
      const pageParams = {...this.page};
      delete pageParams.totalNum;

      const {page, list = []} = await getHouseAllListApi({
        ...formData,
        ...pageParams,
      });
      this.page.totalNum = page.totalNum;
      // console.log(list,'currentNodeHouseList')
      this.currentNodeHouseList = list;
      this.page = page;
      // this.$set(list,list[index],list)
      this.$forceUpdate();
      // this.$refs['HouseListTableRef'].getTableData()
      // this.loadingcread = false
      this.loadingq.close();
    },
    // 获取房源状态导航统计
    async getHouseAllListCount() {
      try {
        const floorNumber = this.floorNumber.value;
        const formData = {
          floorNumber,
          uuid: this.uuid,
          keyword: this.formSearch.keyword,
          uuidType: this.uuidType,
        };
        // formData.keyword = this.searchType === 'node' ? keyword : formData.keyword
        //请求接口
        const res = await getCountListAPI({...formData});
        //拆分数据
        const {
          vacancy,
          reserve,
          rent,
          backRent,
          expire,
          maintain,
          clean,
          entire,
          joint,
        } = res.info;
        //循环判断赋值
        for (let item of this.houseStatus) {
          if (item.code === 600001) this.$set(item, "value", vacancy);
          if (item.code === 600002) this.$set(item, "value", reserve);
          if (item.code === 600003) this.$set(item, "value", rent);
          if (item.code === 600004) this.$set(item, "value", backRent);
          if (item.code === 600005) this.$set(item, "value", expire);
          if (item.code === 600006) this.$set(item, "value", maintain);
          if (item.code === 600007) this.$set(item, "value", clean);
        }
        //循环判断赋值
        for (let item of this.leaseStatus) {
          if (item.code === 600331) this.$set(item, "value", entire);
          if (item.code === 600332) this.$set(item, "value", joint);
        }
        // this.$forceUpdate()  //强制刷新页面
      } catch (e) {
        console.error("获取房源列表", e);
      }
    },
    handleFloor(item) {
      // debugger;
      // 楼层筛选
      this.activeFloor = item;
      this.getCurrentNodeHouseList();
      // 获取房源状态导航统计
      this.getHouseAllListCount();
    },
    // 打开新增房源弹窗
    handleAddHouse() {
      this.$refs.layerHouseRef.layerAddHouseInit(this.numberSum);
    },
    // 点击房源打开右侧房源详情抽屉层
    handleClickHouse(data) {
      console.log(data,'第一层代码传递房间数据')
      this.$refs.drawerHouseRef.drawerHouseInfoInit(data);
    },
    // pageSize 改变时会触发
    sizeChange(value) {
      this.page.pageSize = value;
      this.getCurrentNodeHouseList();
    },
    // currentPage 改变时会触发
    pageChange(value) {
      this.page.page = value;
      this.getCurrentNodeHouseList();
    },
    // 点击头部活动中
    getActivity() {
      this.loadingq = this.$loading({
        lock: true,
        text: '房源加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.activeHouseTab = null;
      this.state = 0;
      this.activity = true;
      this.getCurrentNodeHouseList();
    },
    //2023.07.27张晓瑜新增列表
    getHouseListTableData(params) {
      const floorNumber = this.floorNumber.value;
      const keyword = this.currentNode.name;
      const formData = {
        ...this.formSearch,
        floorNumber,
        state: this.state,
        whole: this.whole,
        activity: this.activity,
      };
      formData.uuidType = this.uuidType
      formData.keyword =
          this.searchType === "node" ? keyword : formData.keyword;
      // 获取房源管理列表
      return getHouseAllListApi({...params, ...formData});
    },

    handleExport() {
      const that = this;
      const floorNumber = this.floorNumber.value;
      // const keyword = this.currentNode.name
      const formData = {
        ...this.formSearch,
        floorNumber,
        state: this.state,
        whole: this.whole,
        activity: this.activity,
        buildUuid: this.buildUuid,
        buildType: this.buildType,
      };
      // formData.keyword = this.searchType === 'node' ? keyword : formData.keyword
      const pageParams = {...this.page};
      delete pageParams.totalNum;
      const paramData = {...formData, ...pageParams};
      const loadingOptions = that.loadingOptions;
      const loading = this.$loading({...loadingOptions});
      apartmentExportApi(paramData).then((res) => {
        downloadByData({data: res, filename: `房源管理${timeFormat(new Date(), "yyyy-MM-dd-HH-mm-ss")}.xlsx`,});
      }).finally(() => loading.close());
    },
    // 批量禁止缴费事件
    myhandleSelectionChange(row) {
      // console.log(row)
      this.rowlist = row.map(item => {
        return item.apartmentUuid
      })

      // console.log(this.rowlist)
    },
    jiejinclick(isvalue) {
      if (this.rowlist.length === 0) {
        this.$message({
          type: 'info',
          message: '请先选择房间'
        });
        return
      }

      let titlevalue = '是否批量禁用所选房间？'
      let messagevalue = '批量禁止缴费成功！'
      if (isvalue) {
        titlevalue = '是否批量解禁所选房间'
        messagevalue = '批量解禁成功！'
      }
      this.$confirm(titlevalue, '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(async () => {
            if (isvalue) {
              // 解开禁用接口
              await getHouseListByCommunityfalse({uuids: this.rowlist}).then(async (res) => {
                this.getCurrentNodeHouseList()
                console.log(res)
                this.$message({
                  message: messagevalue,
                  type: 'success'
                });
              })
            } else {
              // 开始禁用接口
              await getHouseListByCommunitysiok({uuids: this.rowlist}).then(async (res) => {
                this.getCurrentNodeHouseList()
                console.log(res)
                this.$message({
                  message: messagevalue,
                  type: 'success'
                });
              })
            }
          })
    }
  },
};
</script>

<style lang="scss" scoped>
.house-resource {
  height: VH(980px);

  .form-search {
    position: fixed;
    top: VH(30px);
    left: VW(550px);
  }

  .community-tree-container {
    width: VW(300px);
    height: 100%;
    box-shadow: 6px 0 12px rgba(6, 92, 136, 0.16);

    ::v-deep .el-tree-node {
      white-space: normal; //换行
      .el-tree-node__content {
        height: 100%;
        align-items: start;
      }
    }

    ::v-deep .r-e-tree .el-tree.tree-use-handle .el-tree-node__content {
      padding-right: 0;
    }
  }

  .house-resource-container {
    width: VW(1550px);
    padding: VH(20px) 0;

    .card-search-content {
      padding: 0 0 VH(50px) VW(20px);

      .house-lease-status {
        border-radius: 4px;
        box-shadow: 0 6px 12px rgba(0, 45, 170, 0.16);

        &:first-child {
          margin-right: VW(50px);
        }

        .item {
          color: #666;
          padding: VH(13px) VW(30px);
          font-size: rem(15px);
          cursor: pointer;
          font-family: PingFang SC Regular;

          .value {
            color: #61a7fb;
            padding-left: VW(5px);
          }

          &.is-active-tab,
          &:hover {
            color: #fff;
            background: linear-gradient(80deg, #a8d4ff 0%, #2386f6 100%);

            .value {
              color: #fff;
            }
          }
        }
      }
    }

    .house-node-content {
      font-size: rem(20px);
      font-weight: bold;
      padding: 0 0 VH(30px) VW(20px);

      .floor-number {
        color: #2a8af7;
        padding: 0 VW(10px);
      }

      .house-total {
        color: #2a8af7;
      }
    }

    .house-content {
      height: VH(730px);
    }

    .el-pagination {
      margin: VH(20px) VW(20px);
    }
  }

  .floor-container {
    width: VW(90px);
    margin: VH(40px) VW(20px) VH(10px) 0;
    font-family: PingFang SC Regular;

    .floor-name {
      color: #333;
      height: VH(52px);
      line-height: VH(52px);
      font-size: rem(27px);
      font-weight: 400;
      border-bottom: 1px solid rgba(6, 92, 136, 0.16);
      border-radius: 6px 6px 0 0;
      cursor: pointer;

      &:hover {
        color: #2a8af7;
      }
    }

    .floor-content {
      padding-top: VH(15px);
      max-height: VH(840px);

      .floor {
        height: VH(45px);
        color: #999;
        font-size: rem(20px);
        line-height: VH(45px);
        cursor: pointer;

        &.is-active-floor,
        &:hover {
          color: #fff;
          background: linear-gradient(106deg, #68acfd 0%, #2a80e8 100%);
        }
      }
    }
  }
}
</style>
