<template>
    <section class="bill">
        <div style="text-align: right">
            <el-link type="primary" :underline="false" @click="cui">一键催收</el-link>
            <span style="margin:0 10px"/>
            <!--2022/04/01 王江毅 右上角 添加房租账单-->
            <el-link type="primary" :underline="false" v-if="getButtonMenu('ADDMONEY')" @click="openDialog(null)">添加房租账单</el-link>
        </div>
        <div class="table-title">待处理账单</div>
        <r-e-table ref="communityUNTableRef" :dataRequest="getUnPaidTableData" :columns="houseBillColumns" :height="300"
                   show-summary :summary-method="getSummaries">
            <el-table-column prop="name" label="账单名称" :show-overflow-tooltip="true"/>
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <el-dropdown>
                            <span class="el-dropdown-link">
                                操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="look(row)">
                                    <span class="table-btn">详情</span>
                                </el-dropdown-item>
                                <el-dropdown-item v-if="!row.tuiFang && getButtonMenu('PUTMONEY')" @click.native="update(row)">
                                    <span class="table-btn">修改</span>
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="cui(row)">
                                    <span class="table-btn">催款</span>
                                </el-dropdown-item>
                                <!-- 2024/05/08 张晓瑜新增抵扣 -->
                                <!-- v-if="row.typeName==='押金'" -->
                                <el-dropdown-item @click.native="deduction(row)" >
                                    <span class="table-btn">抵扣</span>
                                </el-dropdown-item>
                                <el-dropdown-item v-if="!row.tuiFang && getButtonMenu('CANCLE_BILL_BUTTON')"
                                                  @click.native="he(row)">
                                    <span class="table-btn">核销</span>
                                </el-dropdown-item>
                              <!--                              2023-11-28-天气晴-中午新店开业踩坑-心情-暴风雨-->
                              <el-dropdown-item v-if="getButtonMenu('DELETECLICK')"
                                                @click.native="deleteclick(row)">
                                <span class="table-btn">删除</span>
                              </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
        <div class="table-title">已处理账单</div>
        <r-e-table ref="communityTableRef" :dataRequest="getPaidTableData" :columns="houseBillColumns" :height="340"
                   show-summary :summary-method="getSummaries">
            <el-table-column prop="comment" label="账单备注" :show-overflow-tooltip="true"/>
            <el-table-column slot="toolbar" label="操作" width="100">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="look(row)">详情</span>
                        <span class="table-btn" v-if="row.stateCode=== 600602 && row.typeName === '押金'" @click="transfer(row)">转移</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <dialog-add-bill ref="dialogAddBill" @clickCancel="clickCancel" :currentHouse="currentHouse" :zUuid="zUuid"/>
        <dialog-add-deduction ref="dialogAddDeduction" @clickCancel="clickCancel" :currentHouse="currentHouse" :zUuid="zUuid"/>

        <r-e-dialog title="详情" class="layer-contract-detail" :visible.sync="detailsDialogVisible" show-footer
                    top="10vh" width="1700px" @click-submit="detailsDialogVisible=false"
                    @click-cancel="detailsDialogVisible=false" @close="detailsDialogVisible=false">
            <bill-details ref="billDetails" :billData="billData"/>
        </r-e-dialog>

        <layer-write-off-bill ref="layerWriteOffBill" @clickCancel="clickCancel"/>

        <layer-transfer-deposit-bill ref="layerTransferDepositBill" @clickCancel="clickCancel"/>
    </section>
</template>

<script>
import {houseBillColumns} from "@/views/rental-management/house-resource/data";
import * as houseApi from "@/api/house-resource";
import {MessageWarning, MessageSuccess} from "@custom/message";
import {getContractByUuidOrLeasorUuid,getdeleteclickApi} from "@/api/contract"
import {getBillsUnpaidListByApartment, getBillsPaidListByApartment} from "@/api/bill";
import {buttonCookies} from "@storage/cookies";
import {numberFormat, timeFormat} from "@custom";

export default {
    name: "bill",
    data() {
        return {
            houseBillColumns: houseBillColumns(this),
            billData: null,
            detailsDialogVisible: false,
            apartmentUuid: null,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    props: {
        currentHouse: {
            type: Object,
            default: () => ({})
        },
        zUuid: {
            type: String,
            default: null,
        }
    },
    components: {
        dialogAddBill: () => import("@/views/rental-management/house-resource/components/part/components/dialog-add-bill"),
        dialogAddDeduction: () => import("@/views/rental-management/house-resource/components/part/components/dialog-add-deduction"),
        billDetails: () => import("@/views/bill-management/rent-withdrawal/components/billDetails"),
        layerWriteOffBill: () => import("@/views/bill-management/payment-record/components/layer-write-off-bill"),
        layerTransferDepositBill: () => import('@/views/bill-management/payment-record/components/layer-transfer-deposit-bill'),
    },
    async created() {
        // 获取用户uuid
        const {leasorUuid, apartmentUuid} = this.currentHouse;
        console.log('this.currentHouse',this.currentHouse);
        this.uuid = leasorUuid;
        this.apartmentUuid = this.zUuid || apartmentUuid;
    },
    methods: {
        //获取未付房租账单
        getUnPaidTableData(params) {
            let data = {...params, apartmentUuid: this.apartmentUuid};
            return getBillsUnpaidListByApartment(this.uuid, data);
        },

        //获取已付房租账单
        getPaidTableData(params) {
            // 获取用户已处理账单
            let data = {...params, apartmentUuid: this.apartmentUuid};
            return getBillsPaidListByApartment(this.uuid, data);
        },

        //打开添加房租账单弹框
        openDialog(data) {
          console.log(data)
            // 获取用户uuid 判断是否存在不存在不能打开
            const {leasorUuid} = this.currentHouse;
            if (leasorUuid != null) this.$refs["dialogAddBill"].openDialog(data);
            else MessageWarning("该房源没有租客,无法添加房租", 1000);
        },
        // 打开账单抵扣弹窗
        openDeductionDialog(data) {
            // 获取用户uuid 判断是否存在不存在不能打开
            const {leasorUuid} = this.currentHouse;
            this.$refs["dialogAddDeduction"].openDialog(data);
        },

        //打开修改房租账单弹框
        update(data) {
            this.openDialog(data);
        },
        // 2024/05/08 张晓瑜新增打开账单抵扣弹框
        deduction(data) {
            this.openDeductionDialog(data);
        },
        //催收
        async cui(data) {
          console.log(data)
          const {startDate, endDate} = data;
          // console.log()
          let dataTime = {billStartDate: timeFormat(new Date(startDate)), billEndDate: timeFormat(new Date(endDate))}
            // 账单开始时间：billStartDate    账单结束时间：billEndDate
            const that = this;
            const {leasorUuid} = that.currentHouse;
            const apartmentUuid = that.apartmentUuid;
            const paramData = {uuid: apartmentUuid, leasorUuid}
            let res = await getContractByUuidOrLeasorUuid(paramData).catch(() => MessageWarning("该房源没有合同,无法催款", 1000));
            if (res) {
                let {msg} = res;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                houseApi.reminder({uuid: msg,...dataTime}).then(res => {
                    MessageSuccess('催款成功');
                }).finally(() => loading.close());
            } else MessageWarning("该房源没有合同,无法催款", 1000);
        },

        //打开核销账单弹框
        he(data) {
            this.$refs["layerWriteOffBill"].openDialog(data);
        },

        //弹框关闭，刷新所有房租账单
        clickCancel() {
            let that = this;
            that.$refs["communityTableRef"].getTableData();
            that.$refs["communityUNTableRef"].getTableData();
        },

        //打开房租账单详情弹窗
        look(data) {

            this.billData = data;
            this.detailsDialogVisible = true;
        },

        //获取按钮权限数组
        getButtonMenu(menu) {
            return JSON.parse(buttonCookies.get()).map(item => item.url).some(v => v === menu);
        },
      // 删除按钮
      deleteclick(row){
        console.log(row)
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {


          await  getdeleteclickApi({uuid:row.uuid}).then(res => {
            console.log(res)
            if(res.msg === '账单删除成功'){
              this.clickCancel()
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            }else {
              this.$message({
                type: 'info',
                message: '删除失败请联系管理员'
              });
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
        //列表合计方法
        getSummaries(param) {
            const {columns, data} = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '合计';
                    return;
                }
                const values = data.filter(item => item.stateCode !== 600604).map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) return prev + curr;
                        else return prev;
                    }, 0);
                    sums[index] = numberFormat(sums[index] / 100);
                } else sums[index] = '';
            });
          //   2024-4-16-新增合计备注不显示-陈守亮
          sums[sums.length-2] = ''
            return sums;
        },

        //打开押金转移接口
        transfer(data) {
            this.$refs["layerTransferDepositBill"].openDialog(data);
        }
    }
};
</script>

<style lang="scss" scoped>

</style>
